@import '../node_modules/react-perfect-scrollbar/dist/css/styles.css';


:root {
  --ck-highlight-marker-custom-yellow: #f8ff00;
  --ck-highlight-marker-custom-green100: #00ff00;
  --ck-highlight-marker-custom-green200: #808000;
  --ck-highlight-marker-custom-green300: #008080;
  --ck-highlight-marker-custom-green400: #008000;

  --ck-highlight-marker-custom-pink: #ff00ff;

  --ck-highlight-marker-custom-blue100: #00ffff;
  --ck-highlight-marker-custom-blue200: #0000ff;
  --ck-highlight-marker-custom-blue300: #000080;

  --ck-highlight-marker-custom-violet: #800080;

  --ck-highlight-marker-custom-red100: #ff0000;
  --ck-highlight-marker-custom-red200: #800000;

  --ck-highlight-marker-custom-darkGrey: #808080;
  --ck-highlight-marker-custom-lightGrey: #c0c0c0;
  --ck-highlight-marker-custom-black: #000;
}

@mixin highlight-marker-color($color) {
  .ckeditor-custom .ck-content .marker-custom-#{$color} {
    background-color: var(--ck-highlight-marker-custom-#{$color});
  }
}

@mixin highlight-pen-color($color) {
  .ckeditor-custom .ck-content .pen-custom-#{$color} {
    color: var(--ck-pen-custom-#{$color});

    /* Override default yellow background of `<mark>` from user agent stylesheet */
    background-color: transparent;
  }
}

@include highlight-marker-color(yellow);
@include highlight-marker-color(green100);
@include highlight-marker-color(green200);
@include highlight-marker-color(green300);
@include highlight-marker-color(green400);
@include highlight-marker-color(pink);

@include highlight-marker-color(blue100);
@include highlight-marker-color(blue200);
@include highlight-marker-color(blue300);

@include highlight-marker-color(red100);
@include highlight-marker-color(red200);

@include highlight-marker-color(violet);

@include highlight-marker-color(darkGrey);
@include highlight-marker-color(lightGrey);
@include highlight-marker-color(black);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8 !important;
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  //background: none !important;
  transition-delay: 50000s;
  font-size: inherit;
  //transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.ck-content {
  width: 100%;
  background: #fff;
  font-size: 1em;
  line-height: 1.6em;
  min-height: var(--ck-sample-editor-min-height);
  padding: 1.5em 2em !important;
}

.ck-sticky-panel__content_sticky {
  z-index: 10000 !important;
}